@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800&family=Montserrat:wght@300;400;500&family=Permanent+Marker&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: "Inter var", -apple-system, "system-ui", "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: 800;
  font-size: 60px;
  margin: 0;
}

h2 {
  font-weight: 600;
  font-size: 20px;
}

h3 {
  font-weight: 400;
  color: #4b5563;
}

button {
  font-family: "Inter", sans-serif;
}
